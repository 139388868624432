import React from "react";
import { graphql } from "gatsby";
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
} from "../lib/helpers";
import BlogPostPreviewList from "../../../../../shared_ui/components/blogPostPreviewList";
import Container from "../components/container";
import GraphQLErrorList from "../components/graphql-error-list";
import SEO from "../components/seo";
import Layout from "../containers/layout";
import clientConfig from "../../client-config";
let awsConfig = {};
try {
  awsConfig = require("../aws-exports").default;
} catch (error) {
  // Do nothing. This mess is only to turn a conditional import from an error into a warning
}

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      socials {
        facebook
        twitter
        linkedin
        youtube
      }
    }
    page: sanityPage(_id: { regex: "/(drafts.|)frontpage/" }) {
      ...PageInfo
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          slug {
            current
          }
        }
      }
    }
  }
`;

const IndexPage = props => {
  const { data, errors } = props;

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }

  const site = (data || {}).site;
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
        .filter(filterOutDocsWithoutSlugs)
        .filter(filterOutDocsPublishedInTheFuture)
    : [];

  if (!site) {
    console.warn(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    );
  }

  const page = data.page || data.route.page;
  const menuItems = page.navMenu && (page.navMenu.items || []);
  const socials = site.socials || {};

  // Commented out because this really clashes with the text colours in BlogPostPreviewList and I am no designer
  // const gradient = {
  // 	from: (site.primaryColor && site.primaryColor.hex) || "#d53369",
  // 	to: (site.secondaryColor && site.secondaryColor.hex) || "#daae51"
  // };

  return (
    <Layout textWhite={false} navMenuItems={menuItems} socials={socials} awsConfig={awsConfig}>
      <SEO
        title={site.title || "Missing title"}
        description={site.description || "Missing description"}
        keywords={site.keywords || []}
        // bodyAttr={{
        // 	class: "leading-normal tracking-normal text-white gradient"
        // }}
        // gradient={gradient}
      />
      <Container>
        <h1>{site.title} Blog</h1>
        <div className="py-6">
          {postNodes && <BlogPostPreviewList nodes={postNodes} clientConfig={clientConfig} />}
        </div>
      </Container>
    </Layout>
  );
};

export default IndexPage;
